<template>
    <div class="container">
        <!-- 顶部搜索 -->
        <el-card>
            <!-- 单行搜索 -->
            <div class="global-search-single">
                <div class="search-item">
                    <label class="search-label">来源:</label>
                    <el-cascader v-model="ordersource" :props="{ checkStrictly: true }" clearable
                        :options="common_info['order.source.list']">
                    </el-cascader>
                </div>
                <div class="search-buttons">
                    <el-button type="primary" @click="handleSource">搜索</el-button>
                    <el-button @click="restting">重置</el-button>
                </div>
            </div>
        </el-card>

        <!-- 中部列表 -->
        <div class="middle-content">
            <el-card>
                <div class="card-body">
                    <div class="title-box">
                        <div class="title">活动积分比例</div>

                    </div>
                    <div class="echart-box">
                        <div id="activity_jifen"></div>
                    </div>
                </div>
            </el-card>
            <el-card>
                <div class="card-body">
                    <div class="title-box">
                        <div class="title">员工兑换比例</div>
                    </div>
                    <div class="echart-box">
                        <div id="staff_exchange"></div>
                    </div>
                </div>
            </el-card>
            <el-card>
                <div class="card-body">
                    <div class="title-box">
                        <div class="title">商品排行榜</div>
                        <div class="right">
                            <div class="item" :class="{ 'active': index == global_data.goods_rank_active }"
                                v-for="(item, index) in dayList" :key="index"
                                @click="handleDays('goods_rank', item.value, index)">{{ item.labal }}</div>
                        </div>
                    </div>
                    <div class="rankconter">
                        <div class="rankcontitle d-flex align-items-center">
                            <span>排行</span>
                            <span>商品名称</span>
                            <span>销量</span>
                            <span>销售额 </span>
                        </div>
                        <div class="rankclist" v-if="global_data?.goods_rank_name?.length">
                            <div class="rankclisted d-flex align-items-center diy-bank-box"
                                v-for="(item, index) in global_data.goods_rank_name" :key="index">
                                <span>
                                    <img src="../../assets/images/top-0.png" alt="" v-if="index == 0" />
                                    <img src="../../assets/images/top-1.png" alt="" v-else-if="index == 1" />
                                    <img src="../../assets/images/top-2.png" alt="" v-else-if="index == 2" />
                                    <template v-else>{{ index + 1 }}</template>
                                </span>
                                <span>
                                    <el-tooltip class="boxicon" effect="light" :content="global_data.goods_rank_name[index]"
                                        placement="top">
                                        <a href="javascript:void(0);">{{ global_data.goods_rank_name[index] }}</a>
                                    </el-tooltip>
                                </span>
                                <span>{{ global_data.goods_rank_num[index] }}</span>
                                <span>{{ global_data.goods_rank_price[index] }}</span>
                            </div>
                        </div>
                        <!-- 暂无数据 -->
                        <div class="pitera" style="display: none">
                            <img src="../../assets/images/home-empty.png" alt="" />
                            <p>暂无数据</p>
                        </div>
                    </div>
                </div>
            </el-card>
            <el-card>
                <div class="card-body">
                    <div class="title-box">
                        <div class="title">员工排行</div>
                        <div class="right">
                            <div class="item" :class="{ 'active': index == global_data.user_rank_active }"
                                v-for="(item, index) in dayList" :key="index"
                                @click="handleDays('user_rank', item.value, index)">{{ item.labal }}</div>
                        </div>
                    </div>
                    <div class="rankconter">
                        <div class="rankcontitle d-flex align-items-center">
                            <span>排行</span>
                            <span>员工账号</span>
                            <span>兑换量</span>
                            <span>兑换积分 </span>
                        </div>
                        <div class="rankclist" v-if="global_data?.user_rank_name?.length">
                            <div class="rankclisted d-flex align-items-center diy-bank-box"
                                v-for="(item, index) in global_data.user_rank_name" :key="index">
                                <span>
                                    <img src="../../assets/images/top-0.png" alt="" v-if="index == 0" />
                                    <img src="../../assets/images/top-1.png" alt="" v-else-if="index == 1" />
                                    <img src="../../assets/images/top-2.png" alt="" v-else-if="index == 2" />
                                    <template v-else>{{ index + 1 }}</template>
                                </span>
                                <span>
                                    <el-tooltip class="boxicon" effect="light" :content="global_data.user_rank_name[index]"
                                        placement="top">
                                        <a href="javascript:void(0);">{{ global_data.user_rank_name[index] }}</a>
                                    </el-tooltip>
                                </span>
                                <span>{{ global_data.user_rank_num[index] }}</span>
                                <span>{{ global_data.user_rank_price[index] }}</span>
                            </div>
                        </div>
                        <!-- 暂无数据 -->
                        <div class="pitera">
                            <img src="../../assets/images/home-empty.png" alt="" />
                            <p>暂无数据</p>
                        </div>
                    </div>
                </div>
            </el-card>
        </div>

        <div class="middle-content">
            <el-card>
                <div class="card-body">
                    <div class="title-box">
                        <div class="title">兑换次数</div>
                        <div class="right">
                            <div class="item" :class="{ 'active': index == global_data.exchange_num_active }"
                                v-for="(item, index) in dayList" :key="index"
                                @click="handleDays('exchange_num', item.value, index)">{{ item.labal }}</div>
                        </div>
                    </div>
                    <div class="echart-box quxian">
                        <div id="exchange_num"></div>
                    </div>
                </div>
            </el-card>
            <el-card>
                <div class="card-body">
                    <div class="title-box">
                        <div class="title">兑换积分</div>
                        <div class="right">
                            <div class="item" :class="{ 'active': index == global_data.exchange_integral_active }"
                                v-for="(item, index) in dayList" :key="index"
                                @click="handleDays('exchange_integral', item.value, index)">{{ item.labal }}</div>
                        </div>
                    </div>
                    <div class="echart-box quxian">
                        <div id="exchange_jifen"></div>
                    </div>
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            ordersource: "", //订单来源
            global_data: {},
            echarts_keys_list: [],
            dayList: [{
                value: 7,
                labal: '7天'
            }, {
                value: 15,
                labal: '15天'
            }, {
                value: 30,
                labal: '30天'
            }, {
                value: '',
                labal: '全部'
            }],
            common_info: {}
        }
    },
    created() {
        let data = {
            keys: ["order.source.list"]
        }
        this.common.getCommonInfo(this, data, res => {
            this.common_info = res;
        });
    },
    mounted() {
        // 获取表格数据
        this.getTableData()
    },
    methods: {
        handleSource() {
            let obj = {}
            if (this.ordersource.length) {
                obj['company_id'] = this.ordersource[0]
                if (this.ordersource.length > 1) {
                    obj['activity_id'] = this.ordersource[1]
                }
            }
            this.getTableData(obj)
        },
        restting() {
            this.ordersource = ""
            this.getTableData()
        },
        handleDays(type, day, index) {
            this.$set(this.global_data, type + '_active', index)
            let obj = {};
            obj['key_list'] = [type]
            if (day) {
                obj['day'] = day
            }
            this.getTableData(obj)
        },
        async getTableData(value = {}) {
            // todo 获取数据
            let res = await this.$get(this.$apis.information_list, { ...value })

            if (res.code != 200) {
                this.$message.error(res.message)
                return false
            }

            // 整理数据
            let res_obj = {}
            res.data.forEach(item => {
                const { key, list } = item;
                if (key == "user_rank" || key == "goods_rank") {
                    res_obj[key + '_price'] = list.map(itm => itm.price)
                } else {
                    res_obj[key + '_list'] = list.map(itm => {
                        return {
                            name: itm.name,
                            value: itm.num
                        }
                    })
                }
                res_obj[key + '_name'] = list.map(itm => itm.name)
                res_obj[key + '_num'] = list.map(itm => itm.num)
                if ((!value?.key_list?.[0] || (value?.key_list?.[0] && value?.key_list?.[0] != key)) && !value?.day) {
                    res_obj[key + '_active'] = 0
                }
            })
            this.global_data = { ...this.global_data, ...res_obj }

            this.renderEcharts()
        },
        renderEcharts() {
            // 获取活动积分比例 ID
            let myChart = this.$echarts.init(document.getElementById("activity_jifen"));
            let option = {
                tooltip: {
                    trigger: "item",
                    formatter: function(data) {
                        return (
                            data.name + "</br>" + "积分：" + data.value + "</br>占比：" + data.percent + "%" + "</br>"
                        );
                    },
                },
                legend: {
                    orient: "vertical",
                    icon: "circle",
                    itemWidth: 6,
                    itemHeight: 6,
                    left: "70%",
                    x: "right",
                    y: "center",
                    color: "#666666",
                    fontSize: "12px",
                    selectedMode: true,
                    data: this.global_data.activity_integral_name
                },
                series: [{
                    name: "各端人数",
                    center: ["30%", "50%"],
                    type: "pie",
                    radius: ["50%", "66%"],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: "center",
                        emphasis: {
                            show: true,
                            fontSize: "14",
                        },
                    },
                    labelLine: {
                        show: false,
                    },
                    data: this.global_data.activity_integral_list
                }]
            };
            myChart.setOption(option);

            // 获取员工兑换比例 ID
            let staffEchart = this.$echarts.init(document.getElementById("staff_exchange"));
            let staffOption = {
                tooltip: {
                    trigger: "item",
                    formatter: function(data) {
                        return (
                            data.name + "</br>" + "员工：" + data.value + "</br>占比：" + data.percent + "%" + "</br>"
                        );
                    },
                },
                legend: {
                    orient: "vertical",
                    icon: "circle",
                    itemWidth: 6,
                    itemHeight: 6,
                    left: "70%",
                    x: "right",
                    y: "center",
                    color: "#666666",
                    fontSize: "12px",
                    selectedMode: true,
                    data: this.global_data.user_exchange_name
                },
                series: [{
                    name: "各端人数",
                    center: ["30%", "50%"],
                    type: "pie",
                    radius: ["50%", "66%"],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: "center",
                        emphasis: {
                            show: true,
                            fontSize: "14",
                        },
                    },
                    labelLine: {
                        show: false,
                    },
                    data: this.global_data.user_exchange_list
                }]
            };
            staffEchart.setOption(staffOption);

            // 兑换次数
            let exchangeum = this.$echarts.init(document.getElementById("exchange_num"));
            let exchangeOption = {
                tooltip: {
                    trigger: "axis",
                },
                grid: {
                    top: "5%",
                    left: "5%",
                    right: "2%",
                    bottom: "7%",
                    containLabel: true,
                },
                xAxis: [{
                    type: "category",
                    boundaryGap: false,
                    axisLabel: {
                        show: true,
                        color: "#999",
                        interval: 0, // 强制显示所有标签
                        rotate: 45   // 标签旋转角度
                    },

                    scrollShow: true,
                    itemCount: 10,
                    axisLine: {
                        show: true, //是否显示轴线
                        lineStyle: {
                            color: "#f7f7f7", //刻度线的颜色
                        },
                    },
                    data: this.global_data.exchange_num_name
                },],
                yAxis: [{
                    type: "value",
                    axisLabel: {
                        show: true,
                        color: "#999"
                    },
                    axisLine: {
                        show: true, //是否显示轴线
                        lineStyle: {
                            color: "#f7f7f7", //刻度线的颜色
                        }
                    }
                }],
                dataZoom: [
                    {
                        type: 'slider', // 使用内置数据区域缩放组件
                        xAxisIndex: 0,  // 与上面的 xAxis 对应
                        start: 0,       // 初始滚动范围的起始百分比
                        end: Math.min(100, (10 / 15) * 100), // 初始滚动范围的结束百分比，最大不超过100%
                        zoomLock: true,  // 锁定滚动
                        moveOnMouseMove: false,
                        brushSelect: false

                    }
                ],
                series: [{
                    name: "兑换次数",
                    type: "line",
                    smooth: true,
                    itemStyle: {
                        color: "rgb(255, 70, 131)",
                    },
                    areaStyle: {
                        color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 0,
                            color: "rgb(255, 70, 131)",
                        },
                        {
                            offset: 1,
                            color: "rgb(255, 158, 68)",
                        },
                        ]),
                    },
                    data: this.global_data.exchange_num_num
                }]
            };
            exchangeum.setOption(exchangeOption);



            // 兑换积分
            let exchangeJifen = this.$echarts.init(document.getElementById("exchange_jifen"));
            let jifenOption = {
                tooltip: {
                    trigger: "axis",
                },
                grid: {
                    top: "5%",
                    left: "5%",
                    right: "2%",
                    bottom: "7%",
                    containLabel: true,
                },
                xAxis: [{
                    type: "category",
                    boundaryGap: false,
                    axisLabel: {
                        show: true,
                        color: "#999",
                        interval: 0, // 强制显示所有标签
                        rotate: 45   // 标签旋转角度
                    },

                    scrollShow: true,
                    itemCount: 10,
                    axisLine: {
                        show: true, //是否显示轴线
                        lineStyle: {
                            color: "#f7f7f7", //刻度线的颜色
                        },
                    },
                    data: this.global_data.exchange_integral_name
                }],
                yAxis: [{
                    type: "value",
                    axisLabel: {
                        show: true,
                        color: "#999",
                    },
                    axisLine: {
                        show: true, //是否显示轴线
                        lineStyle: {
                            color: "#f7f7f7", //刻度线的颜色
                        },
                    },
                },],
                dataZoom: [
                    {
                        type: 'slider', // 使用内置数据区域缩放组件
                        xAxisIndex: 0,  // 与上面的 xAxis 对应
                        start: 0,       // 初始滚动范围的起始百分比
                        end: Math.min(100, (10 / 15) * 100), // 初始滚动范围的结束百分比，最大不超过100%
                        zoomLock: true,  // 锁定滚动
                        moveOnMouseMove: false,
                        brushSelect: false

                    }
                ],
                series: [{
                    name: "兑换积分",
                    type: "line",
                    smooth: true,
                    itemStyle: {
                        color: "rgb(255, 70, 131)",
                    },
                    areaStyle: {
                        color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 0,
                            color: "rgb(255, 70, 131)",
                        },
                        {
                            offset: 1,
                            color: "rgb(255, 158, 68)",
                        },
                        ]),
                    },
                    data: this.global_data.exchange_integral_num
                }]
            };
            exchangeJifen.setOption(jifenOption);
        }
    }
}
</script>

<style lang="scss" scoped>
.container {

    .global-search-single {
        justify-content: flex-start;

        .search-item {
            .search-label {
                width: auto;
            }
        }
    }

    .middle-content {
        display: flex;
        align-items: center;
        margin-top: 20px;

        .el-card {
            flex: 1;
            box-sizing: border-box;
        }

        .el-card+.el-card {
            margin-top: 0;
            margin-left: 20px;
        }

        .card-body {
            .title-box {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .right {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .item {
                        border: 1px solid #eee;
                        border-right: 0;
                        padding: 4px 8px;
                        font-size: 12px;
                        cursor: pointer;

                        &.active {
                            background-color: var(--primary);
                            color: #fff;
                        }

                        &:first-child {
                            border-radius: 4px 0 0 4px;
                        }

                        &:last-child {
                            border-right: 1px solid #eee;
                            border-radius: 0 4px 4px 0;
                        }
                    }
                }

                .title {
                    padding-left: 10px;
                    font-weight: 600;
                    font-size: 16px;
                    color: rgba(0, 0, 0, .8);
                    position: relative;

                    &:before {
                        content: "";
                        width: 3px;
                        height: 13px;
                        border-radius: 10px;
                        background: var(--fontColor);
                        position: absolute;
                        top: 5px;
                        left: 5px;
                        margin-left: -5px;
                    }
                }
            }

            .echart-box {
                width: 100%;
                height: 250px;

                >div {
                    width: 100%;
                    height: 100%;
                }

                &.quxian {
                    height: 320px;

                    >div {
                        padding-top: 30px;
                        padding-left: 20px;
                        box-sizing: border-box;
                    }
                }
            }

            .rankconter {
                width: 100%;
                height: 250px;
                padding-top: 11px;
                box-sizing: border-box;

                .rankclist {
                    overflow: auto;
                    height: 210px;

                    &::-webkit-scrollbar {
                        display: none !important;
                    }
                }

                .rankcontitle {
                    margin-top: 0;
                    margin-bottom: 5px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 17px;
                    color: rgba(0, 0, 0, .6);

                    span:nth-child(1) {
                        margin-right: 22px;
                        width: 25px;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .rankcontitle span:nth-child(2) {
                        display: inline-block;
                        width: 100px;
                    }

                    .rankcontitle span:nth-child(3) {
                        flex: 1;
                        text-align: center;
                    }

                    .rankcontitle span:nth-child(4) {
                        flex: 1;
                        text-align: right;
                    }
                }
            }
        }
    }

}
</style>